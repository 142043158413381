<template>
    <div class="company-edit content-wrapper">
        <rq-banner
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="hasErrors"
        />
        <rq-banner
            variant="system"
            title="READ ONLY"
            icon="fas fa-exclamation-circle"
            :visible="showReadOnlyBanner"
        />
        <rq-page-section :title="companyTitle" :header-size="headerSize" header-border scrollable borderless flex-full>
             <template #header-actions>
                <ul v-if="!hasAccessToCompanyRegion" class="nav">
                    <li class="nav-item">
                        <span class="badge badge-danger rq-section-badge">Company is not in your region</span>
                    </li>
                </ul>
                <ul  class="nav ms-auto" >
                    <li v-if="isConfigurationAndTitleCompany" class="nav-item form-group">
                        <b-form-checkbox id="chk_company_is_settlement_agent"
                            automation_id="chk_company_is_settlement_agent"
                            :disabled="!hasCompanyAccess || readOnly"
                            v-model="companyInfo.isSettlementAgent">
                            Settlement Agent
                        </b-form-checkbox>
                    </li>
                    <li v-if="!isConfigurationAndEscrowAccountCompany" class="nav-item form-group">
                        <b-form-checkbox id="chk_company_place_of_closing"
                            automation_id="chk_company_place_of_closing"
                            :disabled="!hasCompanyAccess || readOnly"
                            v-model="companyInfo.isPlaceOfClosing">
                            Place of Closing
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item form-group">
                        <b-form-checkbox id="chk_company_preferred"
                            automation_id="chk_company_preferred"
                            :disabled="!hasCompanyAccess || readOnly"
                            v-model="companyInfo.isPreferred">
                            Preferred
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item form-group">
                        <b-form-checkbox id="chk_company_inactive"
                            automation_id="chk_company_inactive"
                            :disabled="!hasCompanyAccess || readOnly"
                            v-model="companyInfo.isInactive">
                            Inactive
                        </b-form-checkbox>
                    </li>
                </ul>
                <ul v-if="showActions && hasCompanyAccess" class="nav config-actions">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancelAction">CANCEL</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save_and_add_another"
                            variant="action"
                            v-if="addMode"
                            :disabled="readOnly"
                            @click="onSaveAndAddAnotherClicked">SAVE AND ADD ANOTHER</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            :disabled="readOnly"
                            @click="onSaveClicked">SAVE</b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <b-button
                    automation_id="lnk_back_to_company_list"
                    class="btn btn-link btn-theme"
                    v-if="showCompanyLinks"
                    @click="onBackToCompanyListClick">
                    Back to Company List
                </b-button>
                <b-button
                    automation_id="lnk_view_company_contact_list"
                    class="btn btn-link btn-theme"
                    v-if="(showCompanyLinks || showOrderLinks) && !addMode"
                    @click="onViewCompanyContactsClick">
                    View Company Contacts
                </b-button>
                <b-button
                    class="btn btn-link btn-theme"
                    automation_id="lnk_view_order_contacts"
                    v-if="showOrderLinks && !addMode"
                    @click="onViewOrderContactClick">
                    View File Contacts
                </b-button>
                <b-button
                    class="btn btn-link btn-theme"
                    automation_id="lnk_view_order_contacts"
                    v-if="showSettlementLinks && !addMode"
                    @click="onViewSettlementContactClick">
                    View CDF Contacts
                </b-button>
            </template>

            <company-info
                ref="companyInfo"
                v-model:info="companyInfo"
                v-model:locations="placeOfClosingLocations"
                v-model:logo="companyLogo"
                :configuration-mode="configurationMode"
                :has-company-access="hasCompanyAccess"
                :add-mode="addMode"
                @validation-event="onValidationEvent"
                :read-only="readOnly"
            />

            <company-address-grid
                ref="companyAddressGrid"
                v-model:info="companyInfo"
                v-model:company-addresses="companyAddresses"
                :has-company-access="hasCompanyAccess"
                @address-verified-status="onCompanyAddressVerifiedEvent"
                :read-only="readOnly"
            />
            
            <company-image
                v-model:locations="placeOfClosingLocations"
                v-model:info="companyInfo"
                v-model:logo="companyLogo"
                :value="underwriterCompany"
                :has-company-access="hasCompanyAccess"
                :read-only="readOnly"
            />

            <div class="row mb-1 mt-2">
                <div class="col-auto ms-auto">
                    <!--This will automatically hide if there are less than 2 active sections in group "company-edit"-->
                    <rq-section-expand-collapse-all section-group="company-edit"/>
                </div>
            </div>

            <!--
                Paperless closer stuff to be hidden until it's determined
                whether it will be part of Horizon functionality
            -->
            <!-- <rq-section-card
                title="Paperless Closer Layouts"
                collapsible>
                <rq-selection-list-box
                    left-label="Available Paperless Closer Layouts"
                    right-label="Selected Paperless Closer Layouts"
                    :items.sync="pcLayoutItemData"
                />
            </rq-section-card> -->
            <!---------------------------------------------------------->

            <rq-section-card
                v-if="showSelectionBox"
                title="List Codes"
                section-group="company-edit"
                collapsible>
                <rq-selection-list-box
                    left-label="Available List Codes"
                    right-label="Selected List Codes"
                    v-model:items="listCodeItemData"
                    :disabled="!hasCompanyAccess || readOnly"
                />
            </rq-section-card>

            <title-company-info
                v-if="isTitleCompany"
                :item="titleCompany"
                :read-only="readOnly || !hasCompanyAccess"
                v-model:alta-universal-id="companyInfo.altaUniversalId"
            />

            <underwriter-company-info
                v-if="isUnderwriterCompany"
                ref="underwriterCompanyInfo"
                v-model="underwriterCompany"
                :read-only="readOnly || !hasCompanyAccess"
                @validation-event="onValidationEvent"
            />

            <escrow-account-company-info
                v-if="isEscrowCompany"
                ref="escrowAccountInfo"
                v-model:bank="escrowCompany"
                v-model:positive-pay="positivePay"
                :export-formats="exportFormats"
                :read-only="readOnly"
                @validation-event="onValidationEvent"
            />
            <rq-page-section title="Custom Data" collapsible borderless v-if="hasRolesId">
                <custom-data-container
                    ref="customDataContainer"
                    :reference-table="referenceTable"
                    :reference-table-pk-value="rolesId"
                    :custom-data-tab-id="customDataTabID"
                    v-model:value="customData"
                    v-model:is-valid="customDataIsValid"
                    :read-only="customDataReadOnly"
                    @custom-data-loaded="onCustomDataLoaded"
                ></custom-data-container>
            </rq-page-section>
        </rq-page-section>
    </div>
</template>
<script>
    import { GlobalEventManager } from "@/app.events";
    import { ListBoxItemModel } from "@/shared/models/models";
    import { mapState, mapGetters } from "vuex";
    import { RoleType } from '@/shared/models/enums';
    import CompanyAddressGrid from "./CompanyAddressGrid";
    import CompanyInfo from "./CompanyInfo";
    import CompanyImage from "./CompanyImage";
    import TitleCompanyInfo from "./TitleCompanyInfo";
    import UnderwriterCompanyInfo from "./UnderwriterCompanyInfo";
    import EscrowAccountCompanyInfo from "./EscrowAccountCompanyInfo";
    import RqSelectionListBox from "@/shared/components/rq/list-box/RqSelectionListBox";
    import { Company, LogoDto, TitleCompany, Underwriter, EscrowAccount, CompanyDetailDto, CompanyAddress } from '@order-entry/contacts/models';
    import { PositivePay } from '../../../escrow-accounting/models'
    import { CustomDataReferenceTable } from "@/shared/components/customdata/models";
    import { CustomDataContainer } from "@/shared/components";
    import { UserScreenAccessLevel } from "@/shared/models/enums";

    const defaultErrorMessage = "Please correct the highlighted errors on screen to continue.";

    export default {
        components: {
            CompanyAddressGrid,
            CompanyInfo,
            TitleCompanyInfo,
            UnderwriterCompanyInfo,
            EscrowAccountCompanyInfo,
            RqSelectionListBox,
            CompanyImage,
            CustomDataContainer
        },
        props: {
            configurationMode: { type: Boolean, default: false },
            showActions: { type: Boolean, default: false },
            showSettlementLinks: { type: Boolean, default: false },
            showOrderLinks: { type: Boolean, default: false },
            showCompanyLinks: { type: Boolean, default: false },
            companyId: { type: Number, default: 0 },
            roleTypeId: { type: Number, default: 0 },
            modalMode: { type: Boolean, default: false }
        },
        provide(){
            return {
                imageInfoCallback: {
                    onImageChanged: (data) => {
                        if(data.validImage){
                            //reset to default behavior
                            this.isFormValid = true;
                            this.saveCalled = false;
                            this.errorMessage = defaultErrorMessage;
                        }
                        else{
                            this.isFormValid = false;
                            this.saveCalled = true;
                            this.errorMessage = data.message;
                        }

                    }
                }
            }
        },
        data: function() {
            return {
                companyInfo: new Company(),
                rawPlaceOfClosingLocations: [],
                placeOfClosingLocations: [],
                companyLogo: new LogoDto(),
                companyAddresses: [],
                titleCompany: new TitleCompany(),
                underwriterCompany: new Underwriter(),
                escrowCompany: new EscrowAccount(),
                positivePay: new PositivePay(),
                rawListCodesItems: [],
                rawPCLItems: [],
                listCodeItemData: [],
                pcLayoutItemData: [],
                hasCompanyAccess : true,
                isFormValid: true,
                saveCalled: false,
                cancelNew: false,
                ignoreSave: false,
                actionMode: "Add Company",
                customDataTabID: 0,
                customDataIsValid: true,
                escrowInfoIsValid: true,
                underwriterIsValid: true,
                customData: [],
                originalCustomData: [],
                errorMessage: defaultErrorMessage,
                excludedUnderwriterFields: ["underwriterAgencies", "underwriterPremiumSplits"],
                companyAddressVerified: true
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                order: state => state.orders.order,
                user: state => state.authentication.session.user,
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                isSettlementLocked: state => _.parseBool(state.orders.orderSummary.isSettlementLocked),
            }),
            customDataReadOnly() { return this.readOnly || this.localSecurity.AdditionalFields_ScreenAccess !== UserScreenAccessLevel.Full; },
            exportFormats() { return this.lookupHelpers.getLookupItems(this.lookupItems.EXPORT_FORMATS); },
            regions() { return this.lookupHelpers.getRegions(); },
            addMode() { return _.getNumber(this, "companyInfo.companyID", 0) === 0; },
            hasAccess() {
                if (this.addMode) return true; // Creating a new company we automatically allow to edit screen

                return this.userHasAdminRegion || (this.allowEditCompanyDetails && this.hasAccessToCompanyRegion);
            },
            companyName() { return _.get(this, "companyInfo.name", null); },
            companyTitle() {
                if(this.modalMode) return _.isEmpty(this.companyName) ? "Company Details" : this.companyName;
                let action = this.addMode ? "Add" : "Edit";
                return _.isEmpty(this.companyName) ? `${action} Company` : `${action} Company: ${this.companyName}`;
            },
            companyNotInRegion() { return !this.hasCompanyAccess && !this.addMode; },
            isTitleCompany() { return _.get(this.companyInfo, "roleTypeID", 0) === RoleType.TitleCompany; },
            isUnderwriterCompany() { return _.get(this.companyInfo, "roleTypeID", 0) === RoleType.Underwriter; },
            isEscrowCompany() { return _.get(this.companyInfo, "roleTypeID", 0) === RoleType.Bank; },
            showSelectionBox() { return !this.isTitleCompany && !this.isUnderwriterCompany && !this.isEscrowCompany && !this.addMode; },
            hasOrderID() { return _.gt(_.getNumber(this, "$route.params.orderId", 0), 0); },
            fileReadOnly() { return this.hasOrderID && (this.isFileLocked || this.isSettlementLocked); },
            readOnly() { return !this.allowEditCompanyDetails || this.fileReadOnly; },
            showReadOnlyBanner() {
                return (this.fileReadOnly && this.$route.name !== "oe:oc:company")
                || (!this.allowEditCompanyDetails && !this.fileReadOnly);
            },
            routeToCompanyTab() { return ""; },
            routeToContactTab() { return ""; },
            isConfigurationAndEscrowAccountCompany() { return this.configurationMode && this.isEscrowCompany; },
            isConfigurationAndTitleCompany() { return this.configurationMode && this.isTitleCompany; },
            hasErrors(){ return this.saveCalled && (!this.isFormValid || !this.customDataIsValid || !this.escrowInfoIsValid || !this.underwriterIsValid); },
            listCodeLookupItems() { return _.map(this.lookupHelpers.getLookupItems(this.lookupItems.LIST_CODES), item => new ListBoxItemModel(item)); },
            pclLookupItems() { return _.map(this.lookupHelpers.getLookupItems(this.lookupItems.PAPERLESS_CLOSER_LAYOUTS), item => new ListBoxItemModel(item)); },
            selectedListCodes() { return _.sortBy(_.filter(this.listCodeItemData, { parentIndex: 1 }), "sequence"); },
            selectedPcLayouts() { return _.sortBy(_.filter(this.pcLayoutItemData, { parentIndex: 1 }), "sequence"); },
            localSecurity(){
                return this.securitySettings.findValues(["AllowEditCompanyDetails", "AdditionalFields_ScreenAccess", "EditCompaniesOutsideUsersRegion"]);
            },
            allowEditCompanyDetails() {
                return this.localSecurity.AllowEditCompanyDetails;
            },
            headerSize() {
                return this.modalMode ? "default" : "lg";
            },
            hasAccessToCompanyRegion() {
                let companyRegion = _.find(this.regions, r => _.parseNumber(r.regionID) === _.parseNumber(this.companyInfo.regionID));
                return !_.isNil(companyRegion) || (this.localSecurity.EditCompaniesOutsideUsersRegion && this.companyInfo.regionID === this.globalRegionId);
            },
            userHasAdminRegion() {
                let adminRegion = _.find(this.regions, r => r.isAdminRegion);
                return !_.isNil(adminRegion);
            },
            referenceTable() {
                return CustomDataReferenceTable.OrderContacts;
            },
            rolesId() {
                return _.getNumber(this, "$route.params.rolesId", 0);
            },
            hasRolesId() { return this.rolesId > 0; },
            companyAddressGridHasChanges(){
                let self = this;
                let differences = self.getAuditChanges(self.$refs.companyAddressGrid.items, self.$refs.companyAddressGrid.originals);
                return differences.length > 0;
            },
            companyAddressGridHasUncommittedChanges(){
                    const self = this;
                if(!_.isEmpty(self.$refs.companyAddressGrid) && 
                    !_.isEmpty(self.$refs.companyAddressGrid.gridInstance) &&
                    self.$refs.companyAddressGrid.gridInstance.hasEditData()) return true;
                return false;
            },
            underwriterAgencyHasChanges(){
                let self = this;
                if (!self.isUnderwriterCompany) return false;
                let differences = self.getAuditChanges(self.$refs.underwriterCompanyInfo.underwriterAgencies, self.$refs.underwriterCompanyInfo.originalUnderwriterAgencies);
                return differences.length > 0;
            },
            underwriterPremiumSplitHasChanges(){
                let self = this;
                if (!self.isUnderwriterCompany) return false;
                let differences = self.getAuditChanges(self.$refs.underwriterCompanyInfo.underwriterPremiumSplits, self.$refs.underwriterCompanyInfo.originalUnderwriterPremiumSplits);
                return differences.length > 0;
            }
        },
        created() {
            this.initializeEvents();
            this.initializeCompany();
        },
        beforeUnmount () {
            if(this.modalMode) return;
            GlobalEventManager.unregister(this);
        },
        beforeRouteLeave(to, from, next) {
            const self = this;
            if(to && _.has(to.params, "orderId") && !to.params.orderId) {
                next();
                return;
            }

            // when user cancels creating new company or when user clicks save
            if(this.cancelNew || this.ignoreSave) {
                next();
            }
            else {
                let toBeUpdatedCompany = self.generateCompanyDetailDto();
                let objectChanges = [];

                if(this.originalCompanyDetail)
                    objectChanges = self.getAuditChanges(this.originalCompanyDetail, toBeUpdatedCompany, this.excludedUnderwriterFields);

                if(objectChanges.length > 0 || self.addMode) {
                    self.onSaveAction({
                        userInitiated: false
                    })
                    .then((canContinue) => {
                        if(!canContinue) return;
                        next();
                    });
                }
                else {
                    next();
                }
            }
        },
        methods: {
            initializeEvents() {
                if(this.modalMode) return;
                GlobalEventManager.onSave(this, this.onSaveAction);
                GlobalEventManager.onCancel(this, this.onCancelAction);
            },
            initializeCompany() {

                if(this.companyId && this.companyId > 0) {
                    this.actionMode = "Edit Company";
                    this.fetchCompanyDetail(this.companyId);
                }
                else {
                    this.actionMode = "Add Company";
                    this.createEmptyCompany();
                }
            },
            fetchCompanyDetail(companyId) {
                const self = this;
                const getCompanyDetailPromise = self.$api.CompaniesApi.getCompanyDetail(companyId);
                self.$rqBusy.wait(getCompanyDetailPromise)
                .then(result=>{
                    self.loadCompanyDetail(result);
                })
                .catch(error=>{
                    self.$toast.error(`Error getting company details: ${error.errorMessage}`);
                });
            },
            saveCompanyAddressGridUncommittedChanges() {
                const self = this;
                let promises = [];
                if(self.companyAddressGridHasUncommittedChanges) {
                    promises.push(self.$refs.companyAddressGrid.gridInstance.saveEditData());
                }
                return Promise.all(promises);
            },

            onCompanyAddressVerifiedEvent(status) {
                this.companyAddressVerified = status;
            },

            onSaveAndAddAnotherClicked() {
                this.onSaveAction({
                    addAnother: true,
                    userInitiated: true
                });
            },
            onSaveClicked() {
                this.onSaveAction({
                    userInitiated: true
                });
            },
            async onSaveAction(args={}){
                if(!this.hasAccess || this.readOnly) return;
                this.saveCalled = true;
                const validateRef = r => _.invoke(this, `$refs.${r}.validate`);

                this.escrowInfoIsValid = (this.isEscrowCompany) ? validateRef("escrowAccountInfo") : true;
                this.underwriterIsValid = (this.isUnderwriterCompany) ? validateRef("underwriterCompanyInfo") : true;

                validateRef("companyInfo");
                validateRef("customDataContainer");

                let isValidCompanyInfo = _.get(this, "$refs.companyInfo.isValid");
                if (!isValidCompanyInfo
                    || !this.customDataIsValid
                    || !this.escrowInfoIsValid
                    || !this.underwriterIsValid) {
                    return false;
                }

                return this.addMode
                    ? await this.addCompany(args)
                    : await this.updateCompany(args);
            },
            onCancelAction() {
                let self = this;
                _.invoke(self, "$refs.customDataContainer.resetFieldValidations");
                self.saveCalled = false;
                if(self.addMode)
                {
                    self.cancelNew = true;
                    self.$emit("company-cancel-add");
                }
                else {
                    let currentCompany = self.generateCompanyDetailDto();
                    let changes = self.getAuditChanges(self.originalCompanyDetail, currentCompany, this.excludedUnderwriterFields);
                    let customDataChanges = _.differenceWith(this.customData, this.originalCustomData, _.isEqual);
                    if (changes.length > 0 || customDataChanges.length > 0) {
                        self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", () => {
                            self.resetCompany();
                            _.invoke(self, "$refs.customDataContainer.initialize");
                        });
                    } else {
                        self.$toast.info("No Changes Detected.");
                    }
                }
            },
            onBackToCompanyListClick() {
                let self = this;
                self.$emit('back-to-company-list', {
                    companyId: self.companyInfo.companyID,
                    regionId: self.companyInfo.regionID
                });
            },
            onViewCompanyContactsClick() {
                let self = this;
                self.$emit("view-company-contacts", {
                    companyId: self.companyInfo.companyID,
                    regionId: self.companyInfo.regionID,
                    companyName: self.companyName,
                    companySalesRepStaffId: self.companyInfo.salesRepresentativeStaffID
                });
            },
            onViewOrderContactClick() {
                this.$emit('view-order-contacts');
            },
            onViewSettlementContactClick() {
                this.$emit('view-settlement-contacts');
            },
            onValidationEvent(e) {
                this.errorMessage = defaultErrorMessage;
                this.isFormValid = e.isValid;
                if(this.isFormValid)
                    this.saveCalled = false;
            },
            loadCompanyDetail(result){
                this.companyInfo = new Company(result.companyInfo);
                this.companyLogo = new LogoDto(result.logoData);
                this.companyAddresses = _.map(result.companyAddresses, (address) => new CompanyAddress(address));
                this.titleCompany = new TitleCompany(result);
                this.underwriterCompany = new Underwriter(result);
                this.escrowCompany = new EscrowAccount(result);
                this.positivePay = new PositivePay(result.positivePayDetail);
                this.rawListCodesItems = result.selectedListCodes;
                this.rawPCLItems = result.selectedPCL;

                this.loadListSelectionData();
                this.placeOfClosingLocations = result.placeOfClosingLocations;
                this.hasCompanyAccess = this.hasAccess;
                this.originalCompanyDetail = new CompanyDetailDto({
                    companyInfo: this.companyInfo,
                    companyAddresses: this.companyAddresses,
                    placeOfClosingLocations: [...result.placeOfClosingLocations],
                    logoData: this.companyLogo,
                    titleCompanyDetail: this.titleCompany,
                    underwriterCompanyDetail: this.underwriterCompany,
                    escrowCompanyDetail: this.escrowCompany,
                    positivePayDetail: this.positivePay,
                    selectedListCodes: this.rawListCodesItems,
                    selectedPCL: this.rawPCLItems
                });
            },
            createEmptyCompany(){
                this.companyInfo.roleTypeID = this.roleTypeId;
                this.companyInfo.regionID = this.modalMode ? this.order?.regionID : this.user?.regionID;
                this.originalCompanyDetail = this.generateCompanyDetailDto();
            },
            modalSave() {
                return this.onSaveAction();
            },
            addCompany(args) {
                let self = this;
                let toBeAddedCompany = {
                    companyInfo: this.companyInfo,
                    placeOfClosingLocations: this.placeOfClosingLocations,
                    logoData: this.companyLogo,
                    titleCompanyDetail: this.titleCompany,
                    underwriterCompanyDetail: this.underwriterCompany,
                    escrowCompanyDetail: this.escrowCompany,
                    positivePayDetail: this.positivePay
                };

                if (self.isUnderwriterCompany) {
                    toBeAddedCompany.underwriterCompanyDetail.underwriterAgencies = self.prepUnderwriterAgencyIds();
                    toBeAddedCompany.underwriterCompanyDetail.underwriterPremiumSplits = self.prepPremiumSplitRecordsToBeSaved();
                }

                return self.saveCompanyAddressGridUncommittedChanges().then(()=> {
                    if(self.companyAddressVerified == false) return Promise.reject();
                    toBeAddedCompany.companyAddresses = self.prepareCompanyAddresses();
                    let promise = self.$api.CompaniesApi.saveCompanyDetail(toBeAddedCompany, {});
                    return self.$rqBusy.wait(promise)
                    .then(result => {
                        self.$toast.success({ message: 'Company Saved Successfully' });
                        if(self.modalMode){
                            return Promise.resolve(result);
                        }
                        else {
                            GlobalEventManager.saveCompleted({ success: true });
                            self.$events.emit("company-contact-list:refresh");
                            if (args?.userInitiated && args?.addAnother) {
                                self.resetCompany();
                                self.$refs.companyInfo.reset();
                            }
                            else if (args?.userInitiated) {
                                self.loadCompanyDetail(result);
                            }
                            else {
                                return Promise.resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        self.$toast.error(`Failed to Save Company: ${error.message}`);
                    });
                });
            },
            updateCompany(args) {
                let self = this;
                let toBeUpdatedCompany = self.generateCompanyDetailDto();
                let objectChanges = self.getAuditChanges(self.originalCompanyDetail, toBeUpdatedCompany, self.excludedUnderwriterFields);
                let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);
                let companyAddressGridChanges = self.companyAddressGridHasChanges || self.companyAddressGridHasUncommittedChanges;
                if(self.customDataReadOnly && !_.isEmpty(customDataChanges)) return;
                if(objectChanges.length > 0 || customDataChanges.length > 0 || self.underwriterAgencyHasChanges || self.underwriterPremiumSplitHasChanges || companyAddressGridChanges) {
                    if (self.isUnderwriterCompany) {
                        toBeUpdatedCompany.underwriterCompanyDetail.underwriterAgencies = self.prepUnderwriterAgencyIds();
                        toBeUpdatedCompany.underwriterCompanyDetail.underwriterPremiumSplits = self.prepPremiumSplitRecordsToBeSaved();
                    }
                    let nonArrayChange = self.generateNonArrayChanges();
                    
                    return self.saveCompanyAddressGridUncommittedChanges().then(()=> {
                        
                        if(self.companyAddressVerified == false) return Promise.reject();
                        toBeUpdatedCompany.companyAddresses = self.prepareCompanyAddresses();
                        let promise = self.$api.CompaniesApi.saveCompanyDetail(toBeUpdatedCompany, nonArrayChange);
                        return self.$rqBusy.wait(promise)
                        .then(result => {
                            self.$toast.success({ message: 'Company Saved Successfully' });
                            if(self.modalMode) {
                                return Promise.resolve(result);
                            }
                            else {
                                GlobalEventManager.saveCompleted({ success: true });
                                self.$events.emit("company-contact-list:refresh");
                                if(args && args.userInitiated) {
                                    // user initiated save
                                    self.ignoreSave = true;
                                    self.$emit('company-update-success', { companyId: result.companyInfo.companyID });
                                }
                                else {
                                    return Promise.resolve(true);
                                }
                            }
                        })
                        .then(result => {
                            if(!self.hasRolesId || self.customDataReadOnly)
                                return Promise.resolve(true);
                            const savePromise = self.$api.CustomDataApi.saveCustomData(customDataChanges);
                            return self.$rqBusy.wait(savePromise);
                        })
                        .then(result => {
                            _.invoke(self, "$refs.customDataContainer.initialize");
                            return Promise.resolve(true);
                        })
                        .catch(error => {
                            self.$toast.error(`Failed to Update Company: ${error.message}`);
                        });
                    });
                }
                else {
                    self.$toast.info("No Changes Detected.");
                    return Promise.resolve(false);
                }
            },
            loadListSelectionData() {
                let mapItems = (items,parentIndex) => _.map(items, item => new ListBoxItemModel({ ...item, parentIndex }));
                let availableListCodes = mapItems(_.filter(this.listCodeLookupItems, item => _.every(this.rawListCodesItems, item2 => _.parseNumber(item2.id,0) !== item.itemID)),0);
                let selectedListCodes = mapItems(this.rawListCodesItems, 1);
                this.listCodeItemData = _.concat(availableListCodes, selectedListCodes);

                // let selectedPcLayouts = mapItems(this.rawPCLItems);
                // let availablePcLayouts = _.filter(this.pclLookupItems, item => _.every(this.rawPCLItems, item2 => item2.id !== item.itemID));
            },
            generateCompanyDetailDto() {
                let mapItems = (data) => _.map(data, item => ({ id: item.itemID, name: item.itemName }));
                let companyDetailDto = new CompanyDetailDto({
                    companyInfo: _.clone(this.companyInfo),
                    companyAddresses: this.prepareCompanyAddresses(),
                    placeOfClosingLocations: _.clone(this.placeOfClosingLocations),
                    logoData: this.companyLogo,
                    titleCompanyDetail: this.titleCompany,
                    underwriterCompanyDetail: this.underwriterCompany,
                    escrowCompanyDetail: this.escrowCompany,
                    positivePayDetail: this.positivePay,
                    selectedListCodes: mapItems(this.selectedListCodes),
                    selectedPCL: mapItems(this.selectedPcLayouts)
                });
                return companyDetailDto;
            },
            generateNonArrayChanges() {
                let originalObject = new CompanyDetailDto({
                    companyInfo: this.originalCompanyDetail.companyInfo,
                    logoData: this.originalCompanyDetail.logoData,
                    titleCompanyDetail: this.originalCompanyDetail.titleCompanyDetail,
                    underwriterCompanyDetail: this.originalCompanyDetail.underwriterCompanyDetail,
                    escrowCompanyDetail: this.originalCompanyDetail.escrowCompanyDetail,
                    positivePayDetail: this.originalCompanyDetail.positivePayDetail
                });
                let currentObject = new CompanyDetailDto({
                    companyInfo: this.companyInfo,
                    logoData: this.companyLogo,
                    titleCompanyDetail: this.titleCompany,
                    underwriterCompanyDetail: this.underwriterCompany,
                    escrowCompanyDetail: this.escrowCompany,
                    positivePayDetail: this.positivePay
                });
                return this.getAuditChanges(originalObject, currentObject, this.excludedUnderwriterFields);
            },
            resetCompany() {
                this.companyInfo = this.originalCompanyDetail.companyInfo;
                this.placeOfClosingLocations = this.originalCompanyDetail.placeOfClosingLocations;
                this.companyLogo = this.originalCompanyDetail.logoData;
                this.companyAddresses = this.originalCompanyDetail.companyAddresses;
                this.titleCompany = this.originalCompanyDetail.titleCompanyDetail
                this.underwriterCompany = this.originalCompanyDetail.underwriterCompanyDetail;
                this.escrowCompany = this.originalCompanyDetail.escrowCompanyDetail;
                this.positivePay = this.originalCompanyDetail.positivePayDetail;
                this.loadListSelectionData();
                this.$refs.companyAddressGrid.refresh();
            },
            onCustomDataLoaded(e) {
                this.originalCustomData = _.cloneDeep(e);
            },
            prepareCompanyAddresses(){
                const self = this;
                let items = [];
                
                if(!_.isNil(self.$refs.companyAddressGrid?.items))
                    items = self.$refs.companyAddressGrid?.items;

                _.map(items, item => {
                    if (item.isNew) {
                        item.companyAddressID = -1;
                        item.companyID = self.companyInfo.companyID;
                    }
                });
                return items;
            },
            prepUnderwriterAgencyIds(){
                const self = this;
                let items = self.$refs.underwriterCompanyInfo.underwriterAgencies;
                _.map(items, item => {
                    if (item.isNew) {
                        item.underwriterAgencyID = -1;
                        item.companyID = self.underwriterCompany.companyID;
                    }
                });
                return items;
            },
            prepPremiumSplitRecordsToBeSaved(){
                const self = this;
                let items = self.$refs.underwriterCompanyInfo.underwriterPremiumSplits;
                _.map(items, item => {
                    if (item.isNew) {
                        item.underwriterPremiumSplitID = -1;
                        item.companyID = self.underwriterCompany.companyID;
                    }
                });
                return items;
            }
        }
    }
</script>

