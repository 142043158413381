<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="tbl_contacts"
        :title="title"
        title-size="sm"
        :data-source="gridDataSource"
        :config="gridConfig"
        :actions="gridActions"
        :strikethrough-if-true="['isInactive']"
        :prevent-persist-columns="['isInactive']"
        :persist-state="persistState"
        :persist-filters="persistFilters"
        :fixed-header="fixedHeader"
        :single-selection-enabled="singleSelect"
        v-model:search-value="searchValue"
        @action="onGridAction"
        @reset="onGridReset"
        integrated-search
        remote-search
        hide-search
        rq-filters>
        <template #toolbar>
            <ul v-if="allowAdd && hasCompanyFilter && localSecurity.AllowEditContacts" class="nav navbar-nav me-auto">
                <li v-rq-tooltip.hover.top :title="companyAccessTooltip" class="nav-item">
                    <b-btn
                        automation_id="btn_add_contact"
                        variant="theme"
                        size="sm"
                        class="me-2"
                        :disabled="!hasCompanyAccess || disableAddContactButton"
                        @click="$emit('add')">Add
                    </b-btn>
                </li>
            </ul>
            <slot name="toolbar">
                <div class="me-auto"></div>
            </slot>
        </template>
    </rqdx-action-data-grid>
</template>
<script>
    import { DxGridUtils } from "@/shared/utilities";
    import CompanyContactGridMixin from "./CompanyContactGridMixin";
    export default {
        name: "ContactGrid",
        mixins: [CompanyContactGridMixin],
        props: {
            companyId: { type: Number, default: 0 },
            disableAddContactButton: { type: Boolean, default: false }
        },
        data() {
            return {
                itemKey: "contactID",
                itemName: "contact",
                itemNamePlural: "contacts"
            };
        },
        computed: {
            hasCompanyFilter() { return this.companyId > 0; },
            baseFilter() { return this.hasCompanyFilter ? ["CompanyID","=",this.companyId] : []; },
            suppressSearch() { return this.hasCompanyFilter; },
            //localSecurity(){
            //    return this.securitySettings.findValues(["AllowEditContacts"]);
            //}
        },
        methods: {
            getGridColumns() {
                const self = this;
                let columns = [
                    { dataField: "companyName", dataType: "string", width: 220, visible: !self.hasCompanyFilter },
                    { dataField: "contactID", dataType: "number" },
                    { dataField: "firstName", dataType: "string" },
                    { dataField: "lastName", dataType: "string" },
                    { dataField: "emailAddress", caption: "Email", dataType: "string" },
                    { dataField: "phone", dataType: "string" },
                    { dataField: "address1", caption: "Address", dataType: "string" },
                    { dataField: "city", dataType: "string" },
                    {
                        dataField: "state",
                        caption: "State",
                        dataType: "string",
                        lookup: {
                            displayExpr: "id",
                            valueExpr: "id",
                            dataSource: self.stateDataSource
                        }
                    },
                    { dataField: "zip", caption: "Zip" }
                ];
                if(self.showInactive || self.allowActivate) {
                    columns.push({
                        dataField: "isInactive",
                        caption: "Inactive",
                        dataType: "boolean",
                        cellTemplate: DxGridUtils.boolCellTemplate,
                        visible: self.showInactiveColumn
                    });
                }
                let optionalColumns = [
                    {
                        visibleIndex: 0,
                        dataField: "regionID",
                        caption: "Region",
                        calculateDisplayValue: "regionDisplay",
                        calculateSortValue: "regID",
                        alignment: "left",
                        rqFilter: {
                            dataSource: self.regionDataSource,
                            displayExpr: "description",
                            valueExpr: "regionID"
                        }
                    },
                    { dataField: "fullName", dataType: "string" },
                    { dataField: "friendlyName", dataType: "string"},
                    { dataField: "jobTitle", dataType: "string" },
                    { dataField: "notes", caption: "Note", cellTemplate: DxGridUtils.truncateCellTemplate },
                    { dataField: "address2", dataType: "string" },
                    { dataField: "phoneExtension", dataType: "string" },
                    { dataField: "cellPhone", dataType: "string" },
                    { dataField: "fax", dataType: "string" },
                    { dataField: "other", dataType: "string" },
                    { dataField: "direct", dataType: "string"},
                    { dataField: "home", dataType: "string" },
                    { dataField: "contactNMLSID", dataType: "string" },
                    { dataField: "contactStateLicenseID", dataType: "string" },
                    { dataField: "stateIssuedContactLicense", dataType: "string" },
                    { dataField: "isPreferred", caption: "Preferred", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    { dataField: "isDefaultServiceContact", caption: "Archived", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate }
                ];

                let additionalColumns = self.getAdditionalGridColumns(optionalColumns);
                if(!_.isEmpty(additionalColumns))
                    columns.push(...additionalColumns);

                //add custom search highlight to searcheable columns
                const searcheableColumns = [
                    "companyName",
                    "fullName",
                    "firstName",
                    "lastName",
                    "emailAddress",
                    "phone",
                    "address1",
                    "city",
                    "state",
                    "zip",
                    "friendlyName",
                    "contactID"
                ];
                _.forEach(columns, c => {
                    if(_.includes(searcheableColumns, c.dataField)) {
                        c.rqOptions = { highlightSearchText: true };
                    }
                });

                return columns;
            }
        }
    }
</script>
